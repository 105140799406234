import { graphql } from 'gatsby';
import React from 'react';

// Components
import { FilterBar } from 'components';

// Styles
import { inner } from 'styles';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, FormsCards } from 'templates';

// Types
import { Forms } from 'src/types';
interface Props {
  data: {
    forms: {
      nodes: Forms[];
    }[];
  };
}

const FILTERS = [{ title: 'Name', gqlKey: 'form_name' }];

const FormsPage = (props: Props) => {
  const { forms } = props.data;

  return (
    <SEOContent title="Forms">
      <PageLayout>
        <BasicTemplate templateTitle="Forms">
          <div css={inner}>
            <FilterBar
              data={forms}
              filters={FILTERS}
              isHidingFilters={true}
              render={(tableData) => <FormsCards data={tableData} />}
            />
          </div>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default FormsPage;

export const query = graphql`
  query {
    forms: allAirtable(
      filter: { table: { eq: "Forms" }, data: { is_active: { eq: true } } }
    ) {
      nodes {
        data {
          attachments {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          form_name
          link
        }
      }
    }
  }
`;
